<template>
  <div class="frame">
    <label
      v-for="(lb, id) in label"
      :key="`label-${id}`"
      @click="$emit('switch', id)"
    >
      {{ lb }}
      <input :name="`${tag}-switch`" type="radio" :value="id" :checked="id == def"/>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    tag: { type: String },
    label: { type: Array },
    def: {},
  },
}
</script>

<style scoped>
input {
  display: none;
}

.frame {
  padding: 4px;
  width: fit-content;
  height: 36px;
  border: 1px solid var(--light-blue);
  border-radius: 50px;
  display: flex;
}

label {
  height: 100%;
  padding: 2px 16px;
  border-radius: 50px;
  font-size: 14px;
  color: var(--dark-grey);
  cursor: pointer;

  transition: all .1s ease;
}

label:has(input:checked) {
  color: white;
  background-color: var(--primary);
}
</style>