<!--
  - Word Limit error display is not yet implemented
-->
<template>
  <div style="padding-bottom: 32px;">
    <h5>Witness Info</h5>

    <div class="subtitle">
      <p>
        Client Witness
        <span id="CWitness" class="material-symbols-rounded info">info</span>
        <b-tooltip target="CWitness" triggers="hover">
          All uploaded files must not exceed a combined total of 100MB.
        </b-tooltip>
      </p>

      <span
        v-if="!c_state && c_wit.length"
        class="material-symbols-rounded"
        @click="editState(0)"
      >border_color</span>
      <LabelSwitch
        v-else-if="c_state"
        tag="CW"
        :label="['Upload File', 'Input Manually']"
        def="0"
        @switch="changeState($event, 0)"
      />
    </div>

    <template v-if="!c_state">
      <div v-if="!c_wit.length" class="empty-placeholder" @click="c_state = 1">
        <div>
          <span class="material-symbols-rounded">add</span>
          <p class="small-button">Add file</p>
        </div>
      </div>

      <FileProcess
        v-else-if="c_wit[0]?.valid"
        :accept="['pdf','docx','png','jpg','jpeg']"
        :files="c_wit.map(w => w.file)"
        @output-files="
          c_files = $event.map(f => f.file);
          c_wit = $event;
        "
        @delete-entry="$emit('c-input', [true, c_wit])"
        display="true"
      />

      <div v-else>
        <div v-for="(wit, id) in c_wit" :key="`c_wit-${id}`" class="entry">
          <span class="index">{{ id + 1 }}</span>

          <p class="witness-display">
            <b>{{ wit?.name ?? null }}</b>
            <br><span class="breaker"></span>
            {{ wit?.statement ?? null }}
          </p>

          <span class="material-symbols-rounded ex-margin red" @click="$bvModal.show(`c-delete-modal-${id}`)">delete</span>

          <!-- Delete Modal -->
          <b-modal
            :id="`c-delete-modal-${id}`"
            custom-class="delete-modal"
            hide-header
            hide-footer
            centered
          >
            <div class="delete-prompt">
              <p>
                <b>Are you sure you want to delete this entry?</b><br>
                This action cannot be undone.
              </p>
            </div>
            <div class="delete-prompt-options">
              <button class="btn-outlined" @click="$bvModal.hide(`c-delete-modal-${id}`)">
                Cancel
              </button>
              <button class="btn-danger" @click="updateWitness(0, id)">Delete</button>
            </div>
          </b-modal>
        </div>
      </div>
    </template>

    <div v-else>
      <div v-if="c_state === 1" class="file-upload">
        <FileInput class="input" :accept="['pdf','docx','png','jpg','jpeg']" @input-files="c_files = $event" />
        <FileProcess class="process" :accept="['pdf','docx','png','jpg','jpeg']" :files="c_files" @output-files="c_wit = $event" />
      </div>
      <div v-else-if="c_state === 2" class="input-manually">
        <div v-for="(wit, id) in c_input" :key="`c_wit-${id}`">
          <span class="index">{{ id + 1 }}</span>

          <div v-if="!wit.state">
            <textarea
              class="form-control"
              :name="`CW Name ${id}`"
              :ref="`CW_Name_${id}`"
              placeholder="Input Witness' Full Name here. (e.g. John Doe)"
              rows="1"
              v-model.trim="wit.name"
            ></textarea>

            <div class="input-section mt-2">
              <textarea
                class="input-field"
                :name="`CW Statement ${id}`"
                :ref="`CW_Statement_${id}`"
                placeholder="Input Witness' statement here."
                rows="4"
                v-model.trim="wit.statement"
                maxlength="10000"
              ></textarea>
              <p class="limit">{{ getCharCount(wit.statement) }}/10000</p>
            </div>
          </div>
          <p v-else class="witness-display">
            <b>{{ c_wit[id]?.name ?? null }}</b>
            <br><span class="breaker"></span>
            {{ c_wit[id]?.statement ?? null }}
          </p>

          <span class="material-symbols-rounded ex-margin red" @click="updateWitness(0, id)">
            {{ !wit.state ? "close" : "delete" }}
          </span>
          <span
            :class="[
              'material-symbols-rounded',
              (!wit.name || !wit.statement) && 'disabled'
            ]"
            @click="editWitness(0, id)"
          >
            {{ !wit.state ? "check" : "border_color" }}
          </span>
        </div>
        <button v-if="c_input.length < 10" class="small-button btn-transparent" @click="addWitness(0)">
          <span class="material-symbols-rounded">add</span>
          Add more
        </button>
      </div>
      <button
        class="btn-primary save"
        :disabled="invalid_c_wit"
        @click="save(0)"
      >Save</button>
    </div>

    <div class="subtitle ex-margin">
      <p>
        Adverse Witness
        <span id="AWitness" class="material-symbols-rounded info">info</span>
        <b-tooltip target="AWitness" triggers="hover">
          All uploaded files must not exceed a combined total of 100MB.
        </b-tooltip>
      </p>

      <span
        v-if="!a_state && a_wit.length"
        class="material-symbols-rounded"
        @click="editState(1)"
      >border_color</span>
      <LabelSwitch
        v-else-if="a_state"
        tag="AW"
        :label="['Upload File', 'Input Manually']"
        def="0"
        @switch="changeState($event, 1)"
      />
    </div>

    <template v-if="!a_state">
      <div v-if="!a_wit.length" class="empty-placeholder" @click="a_state = 1">
        <div>
          <span class="material-symbols-rounded">add</span>
          <p class="small-button">Add file</p>
        </div>
      </div>

      <FileProcess
        v-else-if="a_wit[0]?.valid"
        :accept="['pdf','docx','png','jpg','jpeg']"
        :files="a_wit.map(w => w.file)"
        @output-files="
          a_files = $event.map(f => f.file);
          a_wit = $event;
        "
        @delete-entry="$emit('a-input', [true, a_wit])"
        display="true"
      />

      <div v-else>
        <div v-for="(wit, id) in a_wit" :key="`a_wit-${id}`" class="entry">
          <span class="index">{{ id + 1 }}</span>

          <p class="witness-display">
            <b>{{ wit?.name ?? null }}</b>
            <br><span class="breaker"></span>
            {{ wit?.statement ?? null }}
          </p>

          <span class="material-symbols-rounded ex-margin red" @click="$bvModal.show(`a-delete-modal-${id}`)">delete</span>

          <!-- Delete Modal -->
          <b-modal
            :id="`a-delete-modal-${id}`"
            custom-class="delete-modal"
            hide-header
            hide-footer
            centered
          >
            <div class="delete-prompt">
              <p>
                <b>Are you sure you want to delete this entry?</b><br>
                This action cannot be undone.
              </p>
            </div>
            <div class="delete-prompt-options">
              <button class="btn-outlined" @click="$bvModal.hide(`a-delete-modal-${id}`)">
                Cancel
              </button>
              <button class="btn-danger" @click="updateWitness(1, id)">Delete</button>
            </div>
          </b-modal>
        </div>
      </div>
    </template>

    <div v-else>
      <div v-if="a_state === 1" class="file-upload">
        <FileInput class="input" :accept="['pdf','docx','png','jpg','jpeg']" @input-files="a_files = $event" />
        <FileProcess class="process" :accept="['pdf','docx','png','jpg','jpeg']" :files="a_files" @output-files="a_wit = $event" />
      </div>
      <div v-else-if="a_state === 2" class="input-manually">
        <div v-for="(wit, id) in a_input" :key="`a_wit-${id}`">
          <span class="index">{{ id + 1 }}</span>

          <div v-if="!wit.state">
            <textarea
              class="form-control"
              :name="`AW Name ${id}`"
              :ref="`AW_Name_${id}`"
              placeholder="Input Witness' Full Name here. (e.g. John Doe)"
              rows="1"
              v-model.trim="wit.name"
            ></textarea>

            <div class="input-section mt-2">
              <textarea
                class="input-field"
                :name="`AW Statement ${id}`"
                :ref="`AW_Statement_${id}`"
                placeholder="Input Witness' statement here."
                rows="4"
                v-model.trim="wit.statement"
                maxlength="10000"
              ></textarea>
              <p class="limit">{{ getCharCount(wit.statement) }}/10000</p>
            </div>
          </div>
          <p v-else class="witness-display">
            <b>{{ a_wit[id]?.name ?? null }}</b>
            <br><span class="breaker"></span>
            {{ a_wit[id]?.statement ?? null }}
          </p>

          <span class="material-symbols-rounded ex-margin red" @click="updateWitness(1, id)">
            {{ !wit.state ? "close" : "delete" }}
          </span>
          <span
            :class="[
              'material-symbols-rounded',
              (!wit.name || !wit.statement) && 'disabled'
            ]"
            @click="editWitness(1, id)"
          >
            {{ !wit.state ? "check" : "border_color" }}
          </span>
        </div>
        <button v-if="a_input.length < 10" class="small-button btn-transparent" @click="addWitness(1)">
          <span class="material-symbols-rounded">add</span>
          Add more
        </button>
      </div>
      <button
        class="btn-primary save"
        :disabled="invalid_a_wit"
        @click="save(1)"
      >Save</button>
    </div>

    <b-modal
      id="Witness-change-state"
      hide-header
      hide-footer
      centered
    >
      <div class="delete-prompt">
        <p>
          <b>Are you sure you want to switch input method?</b><br>
          All unsaved data will be lost
        </p>
      </div>
      <div class="delete-prompt-options">
        <button class="btn-outlined" @click="manipState(false)">
          Keep Editing
        </button>
        <button class="btn-danger" @click="manipState(true)">Switch and Delete</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import LabelSwitch from '../../../components/input/LabelSwitch.vue';
import FileInput from '../../../components/input/FileInput.vue';
import FileProcess from '../../../components/input/FileProcess.vue';

export default {
   props: {
    shouldReset: Boolean,
  },
  components: {
    LabelSwitch,
    FileInput,
    FileProcess,
  },

  data() { return {
    // these inputs are corresponded to the `client_witness` & `adverse_witness`(oppose_witness) in API payload
    c_wit: [],
    a_wit: [],
    
    /**
     * toggle between display state/input state
     * [0] display state
     * [1] upload file
     * [2] input manually
     */
    c_state: 0,
    a_state: 0,
    flag: null,

    // store all file inputs
    c_files: [],
    a_files: [],

    // store the manual inputs
    c_input: [],
    a_input: [],
  }},

  computed: {
    invalid_c_wit() {
      return this.invalidWit(this.c_wit) || this.invalidInput(this.c_input);
    },

    invalid_a_wit() {
      return this.invalidWit(this.a_wit) || this.invalidInput(this.a_input);
    },
  },

  watch: {
    c_state(newVal, oldVal) {
      this.$emit("c-input", [
        newVal === 0,
        [...this.c_wit]
      ]);

      if (oldVal && newVal) {
        this.c_files = [];
        this.c_input = [];
      }

      if (newVal === 2) {
        this.c_input = !this.c_wit.length || (this.c_wit[0]?.valid ?? false)
          ? [{ state: 0, name: "", statement: "" }]
          : this.c_wit.map(({name, statement}) => { return {state: 1, name, statement} });
      }

      if (oldVal && newVal) this.c_wit = [];
    },

    a_state(newVal, oldVal) {
      this.$emit("a-input", [
        newVal === 0,
        [...this.a_wit]
      ]);

      if (oldVal && newVal) {
        this.a_files = [];
        this.a_input = [];
      }

      if (newVal === 2) {
        this.a_input = !this.a_wit.length || (this.a_wit[0]?.valid ?? false)
          ? [{ state: 0, name: "", statement: "" }]
          : this.a_wit.map(({name, statement}) => { return {state: 1, name, statement} });
      }

      if (oldVal && newVal) this.a_wit = [];
    },

    shouldReset(newVal) {
      if (newVal) {
        this.resetValues();
      }
    } ,
  },

  methods: {
    getCharCount(t) { return t.length },

    editState(flag) {
      flag = flag ? "a" : "c";

      if (!this[`${flag}_wit`].length || this[`${flag}_wit`][0]?.valid) {
        this[`${flag}_state`] = 1;
      } else {
        this[`${flag}_state`] = 2;
        this.$nextTick(() => {
          document.getElementsByName(`${flag.toUpperCase()}W-switch`)[1].click();
        });
      }
    },

    changeState(e, flag) {
      const state = `${this.flag}_state`;
      this.flag = flag ? "a" : "c";
      if (e !== this[state] - 1) this.$bvModal.show("Witness-change-state");
      document.getElementsByName(`${flag?"A":"C"}W-switch`)[this[state] > 1 ? 1 : 0].click();
    },

    manipState(flag) {
      const state = `${this.flag}_state`;
      this[`${this.flag}_files`] = [];
      if (flag) {
        this[state] = this[state] > 1 ? 1 : 2;
        document.getElementsByName(`${this.flag.toUpperCase()}W-switch`)[this[state] > 1 ? 0 : 1].click();
      }
      this.$bvModal.hide("Witness-change-state");
    },

    invalidWit(wit) {
      return wit.map(e => e.valid ?? true)?.filter(v => !v).length > 0;
    },
    invalidInput(input) {
      return input.map(e => e.state)?.filter(v => !v).length > 0;
    },

    addWitness(flag) {
      this[`${flag?"a":"c"}_input`].push({ state: 0, name: "", statement: "" });
    },

    editWitness(flag, id) {
      flag = flag ? "a" : "c";
      const wit = `${flag}_wit`;
      const input = `${flag}_input`;

      const state = !this[input][id].state;
      
      const name = this[input][id].name;
      const statement = this[input][id].statement;

      const length = this[wit].length;
      if (state && name && statement) {
        if (length <= id) {
          for (var i = 0; i < id - length; i++) this[wit].push({});
          this[wit].push({ name, statement });
        } else {
          this.$set(this[wit], id, { name, statement });
        }

        this.$set(this[input][id], "state", state);
      }
      
      if (!state) this.$set(this[input][id], "state", state);
    },

    updateWitness(flag, id) {
      flag = flag ? "a" : "c";
      const wit = `${flag}_wit`;
      const input = `${flag}_input`;

      const state = !this[input][id].state;

      const name = this[wit][id]?.name ?? null;
      const statement = this[wit][id]?.statement ?? null;
      if (state && name && statement) {
        this.$set(
          this[input],
          id,
          {
            state,
            ...this[wit][id]
          }
        );
      } else {
        this[input].splice(id, 1);
        this[wit].splice(id, 1);
      }

      this.$emit(`${flag}-input`, [true, [...this.a_wit]]);
      this.$bvModal.hide(`${flag}-delete-modal-${id}`);
    },

    save(flag) {
      flag = flag ? "a" : "c";

      this[`${flag}_files`] = this[`${flag}_files`].filter(
        f => this[`${flag}_wit`].map(i => i.file).includes(f)
      );

      this[`${flag}_state`] = 0;
    },
     resetValues() {
      this.c_wit=[];
      this.a_wit=[];
      this.c_files = [];
      this.a_files = [];
      this.c_input = [];
      this.a_input = [];
      this.$emit("c_input", this.c_input);
      this.$emit("a_input", this.a_input);
    },
  }
}
</script>

<style scoped>
.form-control {
  font-size: 14px;
  resize: none;
}

h5 {
  margin: 32px 0px 20px 0px;
}

p {
  margin-bottom: 0px;
}

.subtitle {
  margin-bottom: 8px;
  height: 36px;
  display: flex;
  justify-content: space-between;

  p {
    font-weight: 500;
    display: flex;
    align-items: center;

    span {
      margin-left: 2px;
      color: var(--neutral-black);
    }
  }

  span {
    font-size: 18px;
    color: var(--primary);
    cursor: pointer;
  }

  &.ex-margin {
    margin-top: 32px;
  }
}

.btn-primary.save {
  margin: 12px 0px 0px auto;
}

.entry {
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  span:first-child {
    margin: 9px 20px auto 0px;
    height: 30px;
    aspect-ratio: 1;
    border-radius: 100%;
    color: white;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span:last-child {
    margin: 0px 0px auto 20px;
    font-size: 18px;
    color: var(--status-red);
    cursor: pointer;
  }
}

.witness-display {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1ps solid var(--light-grey);
  background-color: var(--interface-grey);

  b {
    font-weight: 500;
  }
  
  .breaker {
    display: flex;
    width: 100%;
    height: 10px;
    cursor: default;
  }
}
</style>

<!-- Empty Placeholder -->
<style scoped>
.empty-placeholder {
  height: 140px;
  border: 2px dashed var(--mid-grey);
  border-radius: 8px;
  background-color: var(--interface-grey);
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: all .2s ease;

  > div {
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: inherit;
  }

  span {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    font-size: 24px;
    color: var(--dark-grey);
    background-color: var(--light-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: inherit;
  }

  p {
    padding: 2px 8px;
    border-radius: 4px;
    color: var(--primary) !important;
    transition: inherit;
  }

  &:hover {
    span {
      color: var(--light-grey);
      background-color: var(--dark-grey);
    }

    p {
      background-color: var(--light-grey);
    }
  }
}
</style>

<!-- Input Container -->
<style scoped>
.file-upload {
  display: flex;

  .input {
    width: 55%;
    margin-right: 20px;
  }

  .process {
    width: calc(45% - 20px);
    max-height: 200px;
    overflow-y: auto;
  }
}

.input-manually {
  > div {
    display: flex;
    margin-bottom: 20px;
  }

  .index {
    margin: 9px 20px auto 0px;
    height: 30px;
    aspect-ratio: 1;
    border-radius: 100%;
    color: white;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div:has(textarea) {
    position: relative;
    width: 100%;
  }

  textarea {
    padding: 12px;
  }

  span:first-child {
    cursor: default;
  }

  span {
    margin: 0px 0px auto 12px; 
    font-size: 18px;
    cursor: pointer;
    color: var(--primary);
  }

  .ex-margin {
    margin-left: 22px;
  }

  .red {
    color: var(--status-red);
  }

  .input-section {
    border-radius: 8px;
    border: 1px solid #f2f3f3;
    background-color: #fafbfc;
    padding: 12px;
    margin-bottom: 16px;
  }

  .input-field {
    width: 100%;
    border: none;
    background: transparent;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    resize: none;
    padding: 0px;
  }

  .input-field:focus {
    outline: none;
  }

  .limit {
    text-align: right;
    margin-top: 8px;
    color: var(--dark-grey) !important;
  }

  .small-button {
    margin: 0px auto;
  }

  .small-button span {
    margin: 0px 4px 0px 0px;
  }
}
</style>

<!-- Delete Modal -->
<style scoped>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt p {
  margin-bottom: 0px;
  text-align: center;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-black);
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}
</style>